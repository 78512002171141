import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';

import { FaviconsService } from '@zonar-ui/core';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { PermissionsService } from '@zonar-ui/auth';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';

import { environment } from '@environments/environment';
import { DatadogService } from '@services/data-dog.service';
import { SidenavParamsConfig, SidenavFooterConfig, SidenavHeaderConfig, SidenavMenuConfig } from './shared/config/sidenav.config';
import { Company } from './models/company.model';
import { CompanyService } from '@services/company-store.service';
import { AdminService } from './services/admin.service';
import { DeviceDetectionService } from './services/device-detection.service';
import { AssetApiHttpService } from './services/asset-api.http.service';
import { SubscriptionApiHttpService } from './services/subscription-api.http.service';
import { FeatureToggleService } from './services/feature-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  loading = true;

  sidenavParams = SidenavParamsConfig;
  sidenavHeader = SidenavHeaderConfig;
  sidenavMenu = SidenavMenuConfig;
  sidenavFooter = SidenavFooterConfig;

  constructor(
    private authService: AuthService,
    private datadogService: DatadogService,
    private favicon: FaviconsService,
    private _pendoService: PendoService,
    private _permissionsService: PermissionsService,
    private router: Router,
    private zuiAnalytics: ZonarUiAnalyticsService,
    private _companyService: CompanyService,
    private _adminService: AdminService,
    public device: DeviceDetectionService,
    private _assetApiHttpService: AssetApiHttpService,
    private _subscriptionApiService: SubscriptionApiHttpService,
    private _gtcxMobileHelperService: GTCxMobileHelperService,
    private _featureToggleService: FeatureToggleService,
  ) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        distinctUntilChanged((prev, curr) => prev.value === curr.value),
        switchMap((company) =>
          combineLatest([this._assetApiHttpService.getAssets(company.value), this._subscriptionApiService.getAllAlertTypes(company.value)])
        )
      )
      .subscribe(([assets, alertTypes]) => {
        this._assetApiHttpService.setAssetsToCache(assets);
        this._subscriptionApiService.setAlertTypesToCache(alertTypes);
      });

    if (environment.region === 'NA') {
      this.zuiAnalytics.addGtmToDom();
    }

    this._permissionsService
      .getUser()
      .pipe(
        filter((user) => !!user),
        switchMap(({ id }) => this._adminService.getThresholdAdminProfiles(id))
      )
      .subscribe((adminThresholdProfiles) => {
        this._adminService.adminThresholdProfiles$.next(adminThresholdProfiles);
      });

    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        distinctUntilChanged((prev, curr) => prev.value === curr.value),
        takeUntil(this._onDestroy$)
      )
      .subscribe((company) => {
        this._pendoService.initialize();
        this._permissionsService.setCurrentCompanyContextById(company.value);
        this._adminService.initialize(company.value);
        this._gtcxMobileHelperService.setCurrentCompany(company.value);
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && environment.region === 'NA') {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });

    this.authService.isAuthenticated$.pipe(filter((isAuthenticated) => Boolean(isAuthenticated))).subscribe(() => {
      this.datadogService.startSessionReplayRecording();
    });

    combineLatest([
      this._permissionsService.getUser(),
      this._companyService.currentCompany$,
    ]).pipe(
      filter(([user, company]) => !!user && !!company),
      distinctUntilChanged(([_, prevCompany], [__, currCompany]) => prevCompany.value === currCompany.value),
      takeUntil(this._onDestroy$),
    ).subscribe(([user, company]) => this._featureToggleService.initializeDevCycle(user.id, company.value));
  }

  switchToSelectedCompany(incomingCompany: Company): void {
    if (!incomingCompany) {
      this.sidenavParams.disableBackdropClose = true;
      return;
    }

    this._companyService.currentCompany$.next(incomingCompany);
    this.sidenavParams.disableBackdropClose = false;
  }

  onSideNavMobileMenuButtonToggled(event) {
    this.sidenavParams.mobileOpened = event;
  }

  ngOnDestroy() {
    this.datadogService.stopSessionReplayRecording();
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}

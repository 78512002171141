export const environment = {
  name: 'dev',
  production: false,
  region: 'NA',
  adminRole: 'dbe9ffe5-0dd0-49d6-a824-f789f23ade35',
  appUrl: 'https://alertmanagement.dev.zonarsystems.net',
  apiBase: {
    url: 'https://event-subscription-api.dev.zonarsystems.net/v1'
  },
  gtcxMapsBase: 'https://maps.dev.zonarsystems.net',
  activityFeedBase: 'https://activity-feed-ui.dev.zonarsystems.net/',
  thresholdSettingsBase: {
    link: 'https://settings.dev.zonarsystems.net/',
    applicationId: '93818bc1-8c96-4e62-a460-232b7c679239',
    adminRoleId: '602ee7e2-a2ec-4dfa-92d5-53c06796ef20'
  },
  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net/core/v1'
  },
  authEnv: 'development',
  auth: {
    clientID: 'UvZmcxC5IcpP04nVN5Z9FqWOHvhEIzrG',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    applicationId: 'dbe9ffe5-0dd0-49d6-a824-f789f23ade35',
    defaultZonarRole: 'b8e73ba1-577f-4cc4-b29a-324c0d8ec9c9',
    useRefreshTokens: true
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '4-KiGGwpt35kxwSPey_o-w',
    environment: 'env-3'
  },
  datadog: {
    enabled: true,
    applicationId: 'c6726179-145e-4a20-9043-f23f3c26a956',
    clientToken: 'pub7d9e4520371e38855c316f364e250dd2',
    siteUrl: 'us5.datadoghq.com',
    env: 'development'
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'de-DE', 'en-GB', 'it', 'fr-FR', 'de', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  devCycle: {
    clientId: 'dvc_client_e53775ea_d05c_484a_adc6_80e4d946ff92_be0127b'
  },
  userPreferenceBase: 'https://api-dev.zonarsystems.net/alert/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

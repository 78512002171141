import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, combineLatest, forkJoin, merge, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, mapTo, switchMap, take, takeUntil } from 'rxjs/operators';

import { PermissionsService } from '@zonar-ui/auth';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { GTCxMobileHelperService, CHANNEL_NAME } from '@zonar-ui/gtcx-mobile-helper';

import { TOAST_MESSAGE_DURATION_SECONDS, updateUserPreferenceErrorText, updateUserPreferenceSuccessText } from '@app/constants';
import { CompanyService } from '@app/services/company-store.service';
import { UserPreferenceService } from '@app/services/user-preference.service';
import { DialogComponent } from '../dialog/dialog.component';

const TOGGLE_VALUE = {
  OFF: 0,
  ON: 1,
}

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  notificationOptionsForm = new FormGroup({
    email: new FormControl(null),
    fcm: new FormControl(null),
  });
  userPreferenceId: string = null;
  modified: string = null;
  isLoading = true;
  isExpanded = true;
  isGtcxMobile = this._gtcxMobileHelperService.isGtcxMobile();
  userInfo: Record<string, string> = {}
  companyId: string = '';

  emailToggleChange$ = this.notificationOptionsForm.controls.email.valueChanges.pipe(
    switchMap((isEmailOn) => {
      if (isEmailOn) {
        return of({ email: TOGGLE_VALUE.ON})
      } else {
        if (this.isStatusInvalid(isEmailOn, this.fcmControlValue)) {
          return this.getMobilePermissionResult$().pipe(
            switchMap(hasPerm => hasPerm
              ? of({ email: TOGGLE_VALUE.OFF, fcm: TOGGLE_VALUE.ON })
              : of(null)
            ),
            take(1)
          )
        } else {
          return of({ email: TOGGLE_VALUE.OFF})
        }
      }
    })
  )

  fcmToggleChange$ = this.notificationOptionsForm.controls.fcm.valueChanges.pipe(
    switchMap((isFcmOn) => {
      if (isFcmOn) {
        return this.getMobilePermissionResult$().pipe(
          switchMap(hasPerm => hasPerm
            ? of({fcm: TOGGLE_VALUE.ON})
            : of(null),
          ),
          take(1)
        )
      } else {
        return this.isStatusInvalid(isFcmOn, this.emailControlValue)
          ? of({ fcm: TOGGLE_VALUE.OFF, email: TOGGLE_VALUE.ON }) // Turn On Email and turn off FCM
          : of({ fcm: TOGGLE_VALUE.OFF })
      }
    })
  )

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _companyService: CompanyService,
    private _permissionsService: PermissionsService,
    private _userPreferenceService: UserPreferenceService,
    private _notifications: ZonarUiNotificationsService,
    private _gtcxMobileHelperService: GTCxMobileHelperService,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    combineLatest([
      this._permissionsService.getUser(),
      this._companyService.currentCompany$,
    ]).pipe(
      filter(([user, company]) => !!user && !!company),
      switchMap(([user, company]) => {
        this.isLoading = true;
        this.userPreferenceId = null;
        this.modified = null;
        this.notificationOptionsForm.reset({ email: null, fcm: null }, { onlySelf: true, emitEvent: false });

        this.userInfo = { id: user.id, email: user.email };
        this.companyId = company.value;
        return this._userPreferenceService.get(this.companyId, this.userInfo);
      }),
      takeUntil(this._onDestroy$),
    ).subscribe(({ contactPriority, id, modified }) => {
      this.isLoading = false;
      this.userPreferenceId = id;
      this.modified = modified;
      this.notificationOptionsForm.setValue({ email: !!contactPriority.email, fcm: !!contactPriority.fcm }, { onlySelf: true, emitEvent: false });
      if (!this.isGtcxMobile) {
        this.notificationOptionsForm.disable();
      }
    });

    this.registerFormValueChange();
  }

  registerFormValueChange() {
    if (!this.isGtcxMobile) {
      return;
    }

    merge(
      this.fcmToggleChange$,
      this.emailToggleChange$,
    ).pipe(
      exhaustMap((bodyOfToggle) => {
        const patchApi$ = bodyOfToggle ? this.updateUserPreference(bodyOfToggle) : of(null);
        return patchApi$.pipe(
          concatMap(patchResult => forkJoin([of(patchResult), this._userPreferenceService.get(this.companyId, this.userInfo)])
        ))
      }),
      takeUntil(this._onDestroy$),
    ).subscribe(([patchResult, patchedUserPreference ]) => {
      if (patchedUserPreference) {
        const { contactPriority, modified } = patchedUserPreference;
        this.modified = modified;
        this.notificationOptionsForm.setValue({ email: !!contactPriority.email, fcm: !!contactPriority.fcm }, { onlySelf: true, emitEvent: false });
      }

      if (patchResult) {
        this.showSuccessNotification();
      } else {
        this.showErrorNotification();
      }
    })
  }

  getMobilePermissionResult$(): Observable<boolean> {
    const result$ = new Subject<boolean>();

    this._gtcxMobileHelperService.getMobilePermissionResult()
      .pipe(take(1)).subscribe(result => {
        result$.next(result);
        result$.complete();
      });

    this._gtcxMobileHelperService.notifyToMobile(CHANNEL_NAME.NOTIFICATION_PERMISSION);

    return result$.asObservable();
  }

  updateUserPreference(data: Record<string, number>) {
    return this._userPreferenceService.patch(this.userPreferenceId, data, this.modified).pipe(
      mapTo({}),
      catchError(() => of(null))
    );
  }

  showErrorNotification() {
    this._notifications.openError(updateUserPreferenceErrorText, '', TOAST_MESSAGE_DURATION_SECONDS);
  }

  showSuccessNotification() {
    this._notifications.openSuccess(updateUserPreferenceSuccessText, '', TOAST_MESSAGE_DURATION_SECONDS);
  }

  isStatusInvalid(emailStatus: boolean, fcmStatus: boolean) {
    return !emailStatus && !fcmStatus;
   }

  get fcmControlValue(): boolean {
    return this.notificationOptionsForm.controls.fcm.value;
  }

  get emailControlValue(): boolean {
    return this.notificationOptionsForm.controls.email.value;
  }

  openGetMobileAppDialog() {
    this._dialog.open(DialogComponent, {
      data: {
        title: 'Get the GTC mobile app',
        message: `Don't have the mobile app yet? Scan the QR code below with your phone to quickly download and start using while on the go.`,
        // TODO: Use the correct QR code url after GTCx mobile app released on app store
        imageUrl: 'https://s3-alpha-sig.figma.com/img/cfa2/6426/c865ba4613be7d19d7bc4c6f26d96993?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y9FoltF2CBzSQbJtUqvW3EYKywi5-j33wct56tD5hUZqRYZXlu~hs6Uwi9oa3CAoguur~fgNqGZIO8Nxr41~1r9GFR2Awfk31TsClDyaG5jAYmhjIgs~23kiv2cdbYiaujdMhuAWXAQh4UeXA~HVeCvruij8xumZeYDV5fdakFuYkYPK5M3hJomWWU4FKWyGr0LnWc3ZhaqlpaNWKfGaIIa~gIBtr9B8BOuxlidFdPT2A6MmN31WjdzrbBlYXvC7beuJUEL1jOa7Fp-J9FvahLq6GgUvFbtHzreTGBli~Wij1OuN4VMW~X-Q7D5kXEpKfcdO3vaHzuyb~7hG3-DFuw__',
        acceptText: 'Close',
      },
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

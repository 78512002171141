import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '@app/services/admin.service';
import { ENV } from '@environments/environment.provider';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AlertMetadata } from '@app/components/alerts-list/alerts-list.component';
import { CompanyService } from '@app/services/company-store.service';
import { SubscriptionApiHttpService } from '@app/services/subscription-api.http.service';
import { AssetApiHttpService } from '@app/services/asset-api.http.service';

@Component({
  selector: 'app-all-alerts-page',
  templateUrl: './all-alerts.page.component.html',
  styleUrls: ['./all-alerts.page.component.scss']
})
export class AllAlertsPageComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  isAdmin$ = this._adminService.isAnyAdmin$;
  alertsLink: string = this.env.activityFeedBase;
  thresholdSettingsLink: string = this.env.thresholdSettingsBase.link;
  isManagedThresholds$ = this._adminService.isManagedThresholds$;
  displayCTSLink;
  searchInput = new FormControl();

  alertMetadata: AlertMetadata = null;

  constructor(
    @Inject(ENV) private env: any,
    private _adminService: AdminService,
    private _featureToggleService: FeatureToggleService,
    private _companyService: CompanyService,
    private _subscriptionService: SubscriptionApiHttpService,
    private _assetService: AssetApiHttpService
  ) {}

  ngOnInit() {
    this._featureToggleService
      .isFeatureEnabled('cts-admin-link')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((flag) => (this.displayCTSLink = flag));

    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        switchMap((company) => {
          this.alertMetadata = null;

          return combineLatest([
            this._subscriptionService.getSubscriptionsByCompanyId(company.value),
            this._subscriptionService.getAlertTypesFromCache(),
            this._assetService.getAssetsFromCache().pipe(
              map((allAssets) => allAssets?.length || 0),
              catchError(() => of(0))
            )
          ]);
        }),
        map(([alerts, alertTypes, assetsTotal]) => ({ alerts, alertTypes, assetsTotal })),
        takeUntil(this._onDestroy$),
      )
      .subscribe((alertMetadata) => {
        this.alertMetadata = alertMetadata;
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}
